@import "../../node_modules/reset-css/reset.css";
@import "variables";
@import "../../node_modules/spectre.css/src/spectre.scss";

body {
  background-color: #111;
  color: #fff;
  line-height: 1.6;
  padding: 6rem 0 4rem;
}

h1 {
  text-align: center;

  img {
    margin: 0 auto;
    width: 75%;
    height: auto;
  }
}

section {
  margin-top: 2rem;
  padding: 2rem;

  h2 {
    font-weight: 700;
  }
}

.introduction {
  padding: 2rem;
  background-color: #000;
}

.main {
  margin: 4rem 0;
}

.container {
  max-width: 40rem;
}

.badluck {
  background: url(https://s3.wasabisys.com/blocsonic/remix-kits/2024-badluck/bg.jpg) center no-repeat;
  background-size: cover;
}

.dankesun {
  background: url(https://s3.wasabisys.com/blocsonic/remix-kits/2020-dankesun/bg.gif)
    center no-repeat;
  background-size: cover;
}

.louislinggandthebombs {
  background: url(https://s3.wasabisys.com/blocsonic/remix-kits/2021-louislinggandthebombs/bg.gif)
    center no-repeat;
  background-size: cover;
}

.lorenzosmusic {
  background: url(https://s3.wasabisys.com/blocsonic/remix-kits/2024-lorenzosmusic/lorenzosmusic.gif)
    center;
}

.cta {
  display: block;
  text-align: center;
  padding: 1rem;
  background-color: #0f7ffe;
  transition: background-color 150ms ease;

  &,
  &:hover,
  &:focus,
  &:visited {
    color: #fff;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    background-color: darken(#0f7ffe, 7%);
  }
}
